import React from 'react'
import { graphql, PageProps } from 'gatsby'

// Components
import SEO, { SeoProps } from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler, {
  FlexContentFieldProps,
} from 'components/shared/FlexContentHandler'

export interface VacancyContextProps {
  exists: boolean
  title: string
  uri: string
  excerpt: string
  location: string
  hours: string
  level: string
}

export const VacancyContext = React.createContext({
  exists: false,
  title: '',
  uri: '',
  excerpt: '',
  location: '',
  hours: '',
  level: '',
})

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    page: {
      title?: string
      uri: string
      path?: string
      seo: SeoProps
      flexContent: FlexContentFieldProps
      recap: {
        excerpt: string
        hours: string
        level: string
        location: string
      }
      translations: any
    }
  }
  prefix?: string
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title = '', uri, flexContent, recap, locale, seo, translations },
  },
  location,
  prefix = 'Vacancy_Flexcontent',
}) => (
  <VacancyContext.Provider
    value={{
      exists: true,
      title,
      uri,
      excerpt: recap.excerpt,
      location: recap.location,
      hours: recap.hours,
      level: recap.level,
    }}
  >
    <Layout
    // translations={translations} locale={locale.locale}
    >
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </Layout>
  </VacancyContext.Provider>
)

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacancy(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }
  }
`

export default PageTemplate
