import React from 'react'
import { PageProps } from 'gatsby'

// Lazy loading
import loadable from '@loadable/component'

export interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location: PageProps['location']
}

interface PostProps {
  [key: string]: any
}

const TextShell: React.FC<TextProps> = ({ fields, location }) => {
  const types: PostProps = {
    default: loadable(() => import('components/flex/Text/Text')),
    image: loadable(() => import('components/flex/Text/TextImage')),
    icon: loadable(() => import('components/flex/Text/TextIcon')),
    columns: loadable(() => import('components/flex/Text/TextColumns')),
    grid: loadable(() => import('components/flex/Text/TextGrid')),
    vacancy: loadable(() => import('components/flex/Text/TextVacancy')),
    post: loadable(() => import('components/flex/Text/TextPost')),
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
