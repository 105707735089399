/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Images
import Chevron from 'img/chevron.inline.svg'
// import SwitchLocale from 'components/elements/Misc/SwitchLocale'
// import useLayout from 'hooks/useLayout'

const Item = styled(motion.li) <{ issticky: 't' | 'f'; open: boolean }>`
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &:last-child {
    > div a {
      ${ButtonPrimaryCSS};

      max-height: 40px;
      text-transform: uppercase;

      @media (max-width: 991px){
        padding: .5rem 1rem !important;
      }
    }
  }

  & div > a,
  & div > .empty-link {
    color: ${(props) => props.theme.color.light};
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: center;

    & span {
      border-top: 2px solid transparent;
    }

    ${(props) =>
    props.issticky === 't' &&
    css`
        color: ${({ theme }) => theme.color.primary} !important;
      `}

    &[aria-current] {
      & > span {
        ${(props) =>
    props.issticky === 't'
      ? css`
                border-top: 2px solid ${({ theme }) => theme.color.secondary};
              `
      : css`
                border-top: 2px solid ${({ theme }) => theme.color.light};
              `}
      }
    }

    &:hover {
      & > span {
        ${(props) =>
    props.issticky === 't'
      ? css`
                border-top: 2px solid ${({ theme }) => theme.color.primary};
              `
      : css`
                border-top: 2px solid ${({ theme }) => theme.color.secondary};
              `}
      }
    }
  }

  @media (min-width: 1200px) {
    & div > a,
    & div > .empty-link {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  @media (max-width: 1199.98px) {
    & div > a,
    & div > .empty-link {
      font-size: ${({ theme }) => theme.font.size.tiny};
    }
  }

  @media (min-width: 992px) {
    & div > a,
    & div > .empty-link {
      padding: 1.5rem 1rem 1.5rem 1rem;
    }
  }

  @media (min-width: 992px) {
    & div > a,
    & div > .empty-link {
      ${({ open }) =>
    open
      ? css`
              background-color: ${({ theme }) => theme.color.light};
              color: ${({ theme }) => theme.color.dark};
            `
      : css`
              background-color: transparent;
              color: ${({ theme }) => theme.color.light};
            `};
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & div > a,
    & div > .empty-link {
      font-size: 20px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const SVGWrapper = styled.div<{ open: boolean }>`
  margin-left: 15px;

  & > svg {
    transition: all 0.3s ease;

    ${({ open }) =>
    open
      ? css`
            transform: rotate(180deg);
          `
      : css`
            transform: rotate(0deg);
          `};
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
}

const Menu: React.FC<MenuProps> = ({ fields, isSticky }) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => (
        <Item
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          key={index}
          issticky={isSticky ? 't' : 'f'}
          className="h-100"
          open={open === index && l?.submenu}
        >
          <div className="h-100">
            {l?.link?.url === '#' && (
              <div className="empty-link d-flex align-items-center h-100">
                <span className="py-3 px-xl-2">{l?.link?.title}</span>
              </div>
            )}
            {l?.link?.url !== '#' && (
              <NavLink
                to={l?.link?.url || '/'}
                target={`${l?.link?.target || '_self'}`}
                className="d-flex align-items-center h-100"
              >
                <span className="py-3 px-xl-2">{l?.link?.title}</span>
              </NavLink>
            )}
            {/* {l?.submenu && (
              <SVGWrapper
                className="d-lg-none"
                onClick={
                  open === index ? () => setOpen(-1) : () => setOpen(index)
                }
                open={open === index}
              >
                <Chevron />
              </SVGWrapper>
            )} */}
          </div>

          {l?.submenu && (
            <AnimatePresence>
              {open === index && (
                <Submenu parentIndex={index} fields={l.submenu} />
              )}
            </AnimatePresence>
          )}
        </Item>
      ))}
      {/* <Item className="h-100 d-flex align-items-center ps-3 d-none d-lg-flex">
        <SwitchLocale isSticky={isSticky} />
      </Item> */}
    </>
  )
}

export default Menu
