import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'

const StyledDesktop = styled.ul`
  list-style: none;
  z-index: 3;
  padding-inline-start: 10px !important;
`

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
}

const Desktop: React.FC<DesktopProps> = ({ fields, isSticky }) => (
  <StyledDesktop className="d-none d-lg-flex w-100 h-100 justify-content-end align-items-center mb-0">
    <Menu fields={fields} isSticky={isSticky} />
  </StyledDesktop>
)

export default Desktop
