import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Wrapper = styled(motion.div)`
  width: 100%;
  background-color: tomato;
  color: ${({ theme }) => theme.color.light};

  &:before {
    position: absolute;
    width: 2000px;
    height: 100%;
    content: '';
    top: 0;
    right: 99%;
    background-color: tomato;
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.giga};
      font-family: ${({ theme }) => theme.font.family.secondary};
    }

    & p {
      font-size: ${({ theme }) => theme.font.size.bigger};
    }
  }
`

interface NoPostsProps {
  title: string
  body: string
}

const NoPosts: React.FC<NoPostsProps> = ({ title, body }) => (
  <Wrapper
    className="position-relative"
    initial={{ x: -1200 }}
    animate={{ x: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="px-5 py-3">
      <h2>{title}</h2>
      <p>{body}</p>
    </div>
  </Wrapper>
)

export default NoPosts
