import React, { useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Mousewheel, Navigation } from 'swiper'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Circle from 'img/event.inline.svg'
import Arrow from 'img/arrow-circle.inline.svg'

const Content = styled(ParseContent)`
  letter-spacing: 2.5px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.grey};
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 80px;
      line-height: 80px;
    }
  }
`

const TimelineWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & h2 {
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  .custom-swiper-button-next {
    transform: rotate(180deg);
  }
`

const EventWrapper = styled.div`
  border-top: 2px solid ${({ theme }) => theme.color.primary};
`

const EventInner = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const SVGWrapper = styled.div`
  top: -15px;
  left: -12.5px;
`

const GradientStart = styled.div`
  left: 60px;
  bottom: 0;
  background: linear-gradient(
    90.63deg,
    #fff 30.84%,
    rgba(255, 255, 255, 0) 97.88%
  );
  width: 227px;
  height: 100%;
  z-index: 2;
`

const GradientEnd = styled.div`
  right: 0;
  bottom: 0;
  background: linear-gradient(
    267.63deg,
    #fff 40.84%,
    rgba(255, 255, 255, 0) 97.88%
  );
  height: 100%;
  z-index: 2;

  @media (min-width: 992px) {
    width: 227px;
  }

  @media (max-width: 991px) {
    width: 150px;
  }
`

const Arrows = styled.div`
  z-index: 3;

  & svg:hover {
    transform: scale(1.05);
  }

  & .swiper-button-disabled {
    opacity: 0.25;

    & svg:hover {
      transform: unset;
    }
  }
`

const Container = styled.div`
  max-width: calc(((100% - 1100px) / 2) + 1100px) !important;
  margin-right: 0;
`

const ArrowButton = styled.div`
  & svg {
    @media (min-width: 992px) {
      width: 48px;
      height: 48px;
    }

    @media (max-width: 991px) {
      width: 35px;
      height: 35px;
    }
  }
`

interface TimelineProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Timeline
}

const Timeline: React.FC<TimelineProps> = ({ fields }) => {
  SwiperCore.use([Navigation, Mousewheel])

  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)

  return (
    <section className="position-relative">
      <div className="container">
        <div className="row">
          <Content content={fields.description} className="mb-4" />
        </div>
      </div>
      <TimelineWrapper className="position-relative py-5">
        {!start && (
          <GradientStart className="position-absolute d-none d-lg-flex" />
        )}
        {!end && <GradientEnd className="position-absolute" />}
        <Container className="position-relative container py-4">
          <Swiper
            className="pb-3 pt-3 px-3"
            onSlideChange={(slide) => {
              setStart(slide.isBeginning)
              setEnd(slide.isEnd)
            }}
            mousewheel={{ forceToAxis: true }}
            navigation={{
              prevEl: '.custom-swiper-button-prev',
              nextEl: '.custom-swiper-button-next',
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.4,
              },
              576: {
                slidesPerView: 2.4,
              },
              768: {
                slidesPerView: 3.4,
              },
              992: {
                slidesPerView: 4.4,
              },
            }}
          >
            {fields.events?.map((e, index) => (
              <SwiperSlide key={index} className="position-relative">
                <SVGWrapper className="position-absolute">
                  <Circle />
                </SVGWrapper>
                <EventWrapper className="py-4">
                  <EventInner className="px-3">
                    <div className="pe-lg-5">
                      <h2>{e?.title}</h2>
                      <ParseContent content={e?.description} className="pe-3" />
                    </div>
                  </EventInner>
                </EventWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
        <Arrows className="position-relative container">
          <div className="d-flex justify-content-end">
            <ArrowButton
              className="custom-swiper-button-prev me-3"
              role="button"
            >
              <Arrow />
            </ArrowButton>
            <ArrowButton className="custom-swiper-button-next" role="button">
              <Arrow />
            </ArrowButton>
          </div>
        </Arrows>
      </TimelineWrapper>
    </section>
  )
}

export default Timeline
