import React from 'react'
import { PageProps } from 'gatsby'

// Components
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface FragmentProps {
  fields: any
  location: PageProps['location']
}

const Fragment: React.FC<FragmentProps> = ({ fields, location }) => (
  <FlexContentHandler
    prefix="Fragment_FlexFragment"
    fields={fields.element.flex_fragment}
    location={location}
  />
)

export default Fragment
