import React from 'react'
import { PageProps } from 'gatsby'

// Lazy loading
import loadable from '@loadable/component'

export interface ReferencesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_References
  location?: PageProps['location']
  slider?: boolean
  node?: {
    id: string
    title: string
    uri: string
    recap: {
      delivery: string
      scope: string
      shipyard: string
      owner: string
    }
  }
}

interface ReferenceProps {
  [key: string]: any
}

const ReferencesShell: React.FC<ReferencesProps> = ({ fields, location }) => {
  const types: ReferenceProps = {
    highlighted: loadable(
      () => import('components/flex/References/Highlighted')
    ),
    overview: loadable(() => import('components/flex/References/Overview')),
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default ReferencesShell
