import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Header from 'components/layout/Header/Header'
import Footer from 'components/layout/Footer'

// Images
import BG from 'img/background.svg'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'styles/all.css'
import { navigate } from 'gatsby'

export interface LayoutContextProps {
  locale: string
  pageId: number
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
  setLocalLanguage: Function
  localLanguage: string
}

export const LayoutContext = React.createContext({
  locale: '',
  pageId: 0,
  translations: [],
  setIsScrollable: (bool: any) => bool,
  setLocalLanguage: (string: any) => string,
  localLanguage: '',
})

const Main = styled.main`
  background-image: url(${BG});
  background-repeat: repeat-y;
  background-size: 100% auto;
  min-height: 100vh;
`

const Content = styled.div<{ isSticky?: boolean }>``

interface LayoutProps {
  children: any
  locale?: string
  pageId?: number
  translations?: any
  className?: string
}

const Layout: React.FC<LayoutProps> = ({
  children,
  locale = 'en_US',
  translations,
  pageId = 0,
  className = '',
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const [localLanguage, setLocalLanguage] = useState('en_US')

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  if (typeof window !== `undefined`) {
    useEffect(() => {
      if (
        location.pathname === '/aerius-marine.com/' &&
        localStorage.getItem('locale') === 'de_DE'
      ) {
        navigate('/de')
      }
    }, [localStorage.getItem('locale')])
  }

  return (
    <Theme isScrollable={isScrollable}>
      <LayoutContext.Provider
        value={{
          pageId,
          setIsScrollable,
          setLocalLanguage,
          localLanguage,
          locale,
          translations,
        }}
      >
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <Main className="position-relative h-100">
          <Content isSticky={isSticky} className={className ?? undefined}>
            {children}
          </Content>
        </Main>
        <Footer />
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
