/* eslint-disable no-underscore-dangle */
import React from 'react'
import { PageProps } from 'gatsby'

// Shells import
import BannerShell from 'components/flex/Banner/BannerShell'
import FormShell from 'components/flex/Form/FormShell'
import TextShell from 'components/flex/Text/TextShell'
import PostsShell from 'components/flex/Posts/PostsShell'
import Fragment from 'components/flex/Fragment'
import ServicesShell from 'components/flex/Services/ServicesShell'
import Timeline from 'components/flex/Misc/Timeline'
import MarketsShell from 'components/flex/Markets/MarketsShell'
import Whitespace from 'components/flex/Misc/Whitespace'
import Team from 'components/flex/Misc/Team'
import CertificationsShell from 'components/flex/Certifications/CertificationsShell'
import LocationsShell from 'components/flex/Locations/LocationsShell'
import VacanciesShell from 'components/flex/Vacancies/VacanciesShell'
import ReferencesShell from 'components/flex/References/ReferencesShell'
import HeroShell from 'components/flex/Hero/HeroShell'

export interface FlexContentFieldProps {
  flex: Array<{
    fieldGroupName: string
    flex: any
  }>
}

interface FlexContentProps {
  prefix?: string
  fields: FlexContentFieldProps
  location: PageProps['location']
}

interface FlexerProps {
  [key: string]: React.FC<any>
}

interface ComponentProps {
  fields: FlexContentFieldProps
  location: PageProps['location']
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location,
}) => {
  if (!fields) {
    return null
  }

  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Services`]: ServicesShell,
    [`${prefix}_Flex_Markets`]: MarketsShell,
    [`${prefix}_Flex_Certifications`]: CertificationsShell,
    [`${prefix}_Flex_Locations`]: LocationsShell,
    [`${prefix}_Flex_Timeline`]: Timeline,
    [`${prefix}_Flex_Hero`]: HeroShell,
    [`${prefix}_Flex_Whitespace`]: Whitespace,
    [`${prefix}_Flex_Team`]: Team,
    [`${prefix}_Flex_PostTypes`]: PostsShell,
    [`${prefix}_Flex_Vacancies`]: VacanciesShell,
    [`${prefix}_Flex_References`]: ReferencesShell,
    [`${prefix}_Flex_Fragment`]: Fragment,
  }

  if (!fields.flex) {
    return null
  }

  return (
    <>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
          />
        )
      })}
    </>
  )
}

export default FlexContentHandler
