/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Third Party
import styled, { css } from 'styled-components'
import useLayout from 'hooks/useLayout'

const StyledHeader = styled(motion.nav) <{ issticky: 't' | 'f' }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  font-family: ${({ theme }) => theme.font.family.secondary};

  ${(props) =>
    props.issticky === 't' &&
    css`
      background: rgba(243, 243, 243, 0.5);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border: 1px solid rgba(255, 255, 255, 0.18);
    `}

  height: 85px !important;
`

const Brand = styled(NavLink) <{ isSticky: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  border-bottom-right-radius: 50px;
  z-index: 101;
  margin-left: -1px;

  &:hover {
    opacity: 0.85;
  }

  ${(props) =>
    props.isSticky &&
    css`
      background-color: transparent;
    `}

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 100%;
    width: 2000px;
    height: 100%;
    background-color: ${({ theme }) => theme.color.light};

    ${(props) =>
    props.isSticky &&
    css`
        background-color: transparent;
      `}
  }

  @media (min-width: 992px) {
    & > svg {
      height: 55px;
      width: 207px;
    }
  }

  @media (max-width: 991.98px) {
    & > svg {
      height: 35px;
      width: 157px;
    }
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    #263280 3.48%,
    rgba(38, 50, 128, 0) 95.58%
  );
  mix-blend-mode: multiply;
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.15));
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  const layout = useLayout()

  // eslint-disable-next-line
  const query: any = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      en_US: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
              target
            }
            submenu {
              icon {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
          }
        }
      }

      de_DE: wpComponent(databaseId: { eq: 870 }) {
        header {
          menuHeader {
            link {
              title
              url
              target
            }
            submenu {
              icon {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const fields = query[layout.locale]

  return (
    <StyledHeader
      issticky={isSticky ? 't' : 'f'}
      initial={{ height: 110 }}
      animate={isSticky ? { height: 75 } : { height: 110 }}
    >
      {!isSticky && <Gradient className="d-lg-flex d-none w-100 h-100" />}

      <div className="container d-flex align-items-center justify-content-between h-100">
        <Brand
          to={`${{ en_US: '/', de_DE: '/de/' }[layout.locale]}`}
          className="position-relative h-100 d-flex align-items-center pe-4"
          isSticky={isSticky}
        >
          <Logo />
        </Brand>

        <Mobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <Desktop fields={fields} isSticky={isSticky} />
      </div>
    </StyledHeader>
  )
}

export default Header
