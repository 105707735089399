const mainTheme = {
  color: {
    primary: '#263280',
    secondary: '#14753D',
    alternate: '#105e94',
    grey: '#E0E0E0',
    dark: '#1C1C1C',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'poppins, sans-serif',
      secondary: 'prometo, sans-serif',
    },

    size: {
      tiny: '12px',
      small: '14px',
      medium: '16px',
      big: '18px',
      bigger: '20px',
      large: '25px',
      huge: '30px',
      mega: '40px',
      ultra: '50px',
      giga: '65px',
      master: '75px',
    },

    weight: {
      extraLight: 200,
      light: 300,
      regular: 400,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
