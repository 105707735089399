import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

// Images
import Location from 'img/location.inline.svg'
import Email from 'img/email.inline.svg'
import Phone from 'img/phone.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'
import Arrow from 'img/arrow.inline.svg'

const Wrapper = styled.footer`
  background-color: ${(props) => props.theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.extraLight};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.light};
  }

  & a:hover {
    text-decoration: underline;
  }
`

const CTA = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.color.light};
`

const CookiesBtn = styled.button`
   color: ${({ theme }) => theme.color.light};
   font-weight: 200;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  & p {
    margin-bottom: 0;
  }
`

const Menu = styled.div`
  & h2 {
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
  border-bottom: 1px solid ${({ theme }) => theme.color.light};
`

const Contact = styled.div``

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          ctaFooter {
            description
            link {
              title
              url
            }
          }
          menuFooter {
            columns {
              title
              menu {
                link {
                  title
                  url
                }
              }
            }
          }
          documentsFooter {
            link {
              title
              url
            }
          }
          contactFooter {
            location
            email {
              title
              url
            }
            phone {
              title
              url
            }
            link {
              title
              url
            }
            linkedin {
              title
              url
            }
          }
        }
      }
    }
  `)

  const { ctaFooter, menuFooter, documentsFooter, contactFooter }: any =
    fields?.footer

  const date = new Date()

  return (
    <Wrapper>
      <div className="container py-5">
        <CTA className="d-md-flex justify-content-between align-items-center pb-5">
          <Content content={ctaFooter.description} />
          <ButtonSecondary isAnchor to={ctaFooter.link.url}>
            {ctaFooter.link.title}
          </ButtonSecondary>
        </CTA>
        <Menu>
          <div className="row pt-5 pb-4">
            <div className="col-lg-9">
              <div className="d-flex flex-column flex-lg-row justify-content-between pe-5">
                {menuFooter.columns.map((column: any, index: number) => (
                  <div key={index} className="mb-4 mb-lg-0">
                    <h2>{column.title}</h2>
                    <div className="ps-1 mt-3">
                      <ul className="ps-3">
                        {column.menu.map((item: any, subIndex: number) => (
                          <li key={subIndex} className="mb-md-2 pb-md-1">
                            <NavLink to={item.link.url}>
                              {item.link.title}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Contact className="col-lg-3">
              <h2>Contact</h2>
              <div className="d-flex mb-2 mt-3">
                <div style={{ width: 40 }}>
                  <Location />
                </div>
                <Content content={contactFooter.location} />
              </div>
              <div className="d-flex align-items-center mb-2">
                <div style={{ width: 40 }}>
                  <Email />
                </div>
                <a href={contactFooter.email.url}>
                  {contactFooter.email.title}
                </a>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div style={{ width: 40 }}>
                  <Phone />
                </div>
                <a href={contactFooter.phone.url}>
                  {contactFooter.phone.title}
                </a>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div style={{ width: 40 }}>
                  <Linkedin />
                </div>
                <a
                  href={contactFooter.linkedin.url}
                  className="mt-2"
                  target="_blank"
                >
                  {contactFooter.linkedin.title}
                </a>
              </div>
              <NavLink
                to={contactFooter.link.url}
                className="d-flex align-items-center mt-4"
              >
                <span className="me-2 font-weight-bold">
                  {contactFooter.link.title}
                </span>
                <Arrow />
              </NavLink>
            </Contact>
          </div>
        </Menu>
        <div className="row justify-content-center pt-3">
          <div className="col-lg-8">
            <div className="d-flex flex-column flex-sm-row justify-content-around">
              <span>AERIUS Marine &copy; {date.getFullYear()}</span>
              {documentsFooter.map((document: any, index: number) => (
                <NavLink key={index} to={document.link.url}>
                  {document.link.title}
                </NavLink>
              ))}
              {/* @ts-expect-error */}
              <CookiesBtn onClick={() => Cookiebot.show()}>Change cookies</CookiesBtn>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Footer
