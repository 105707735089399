/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled(motion.div)<{ isSticky?: boolean }>`
  @media (min-width: 992px) {
    filter: drop-shadow(-3px 47px 19px rgba(0, 0, 0, 0.18));
    background-color: ${({ theme }) => theme.color.light};
    width: 100%;
    left: 0;

    & > div {
      border-radius: 5px;
    }

    & > div {
      background-color: ${({ theme }) => theme.color.light};
    }
  }

  @media (max-width: 991px) {
    position: relative;
    padding-top: unset;
    width: 100%;
    margin-left: -15%;
  }
`

const Icon = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 64.5px;
    height: 64.5px;
  }

  @media (max-width: 991px) {
    width: 34.5px;
    height: 34.5px;
  }
`

const SubmenuItem = styled(NavLink)`
  & span {
    color: ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 992px) {
    background-color: unset;
  }

  @media (max-width: 991px) {
    background-color: ${({ theme }) => theme.color.light};
  }
`

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
  parentIndex: number
}

const MUST = {
  '2-0': [118, 118, 0.75],
  '2-1': [200, 121, 0.75],
  '2-2': [118, 118, 0.75],
  '2-3': [200, 120, 0.75],
  '2-4': [118, 118, 0.75],
}

const Submenu: React.FC<SubmenuProps> = ({ parentIndex = 0, fields }) => {
  return (
    <Wrapper
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
      transition={{
        duration: 0.2,
      }}
      className="overflow-hidden position-absolute w-100"
    >
      <div className="container py-lg-4">
        <div className="row justify-content-center">
          <div className="d-flex flex-column flex-lg-row justify-content-start">
            {fields.map((f, index) => {
              // @ts-ignore
              const custom = MUST[`${parentIndex}-${index}`]

              return (
                <SubmenuItem
                  key={index}
                  to={f?.link?.url || '/'}
                  className={`px-3 px-lg-0 py-3 me-lg-5 ${
                    custom ? '' : 'd-flex align-items-center'
                  }`}
                >
                  {custom && (

                      <Icon
                        image={f?.icon}
                        alt=""
                        className="me-3 custom mb-4"
                        style={{
                          width: custom[0] * 0.75,
                          height: custom[1] * 0.75,
                        }}
                      />

                  )}
                  {!custom && <Icon image={f?.icon} alt="" className="me-3" />}
                  <div className="pe-2">{f?.link?.title}</div>
                </SubmenuItem>
              )
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Submenu
