import React from 'react'
import { graphql, PageProps } from 'gatsby'

// Components
import SEO, { SeoProps } from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler, {
  FlexContentFieldProps,
} from 'components/shared/FlexContentHandler'

export interface PostContextProps {
  exists: boolean
  title: string
}

export const PostContext = React.createContext({
  exists: false,
  title: '',
})

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    page: {
      title?: string
      path?: string
      seo: SeoProps
      flexContent: FlexContentFieldProps
    }
  }
  prefix?: string
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title = '', flexContent, seo },
  },
  location,
  prefix = 'post_Flexcontent',
}) => (
  <PostContext.Provider value={{ exists: true, title }}>
    <Layout>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </Layout>
  </PostContext.Provider>
)

export const pageQuery = graphql`
  query postById($pageId: String!) {
    page: wpPost(id: { eq: $pageId }) {
      ...generalPostFragment
    }
  }
`

export default PageTemplate
