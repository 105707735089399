import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

// Components
import Menu from 'components/layout/Header/Menu'

// Images
// import Cruise from 'img/cruise.inline.svg'

// Third Party
import styled, { css } from 'styled-components'

const MenuWrapper = styled(motion.div)`
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.125);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  & ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    width: 70%;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div<{ isSticky?: boolean }>`
  height: 2px;
  width: 50%;
  margin: 5px auto;

  ${(props) =>
    props.isSticky
      ? css`
          background-color: ${(props) => props.theme.color.primary};
        `
      : css`
          background-color: ${(props) => props.theme.color.light};
        `}
`

const ShipWrapper = styled.div`
  @media (min-height: 992px) {
    bottom: -5px;
  }

  @media (max-height: 991px) {
    bottom: 100px;
  }
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({
  fields,
  setIsScrollable,
  isSticky,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe isSticky={isSticky} />
        <NavButtonStripe isSticky={isSticky} />
        <NavButtonStripe isSticky={isSticky} />
      </NavButton>
      {isOpen && (
        <AnimatePresence>
          <MenuWrapper
            initial={{ x: '100vw' }}
            animate={{ x: '0vw' }}
            transition={{ stiffness: 10 }}
          >
            {/* <ShipWrapper className="position-absolute">
              <motion.div
                animate={{ x: ['180%', '90%'] }}
                transition={{ duration: 2 }}
              >
                <Cruise />
              </motion.div>
            </ShipWrapper> */}
            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
            </NavButtonClose>
            <ul>
              <Menu fields={fields} />
            </ul>
          </MenuWrapper>
        </AnimatePresence>
      )}
    </div>
  )
}

export default Mobile
