import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Linkedin from 'img/linked.inline.svg'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.primary};
`

const Member = styled.div`
  aspect-ratio: 1/1;

  & span {
    color: ${({ theme }) => theme.color.light};
  }
`

const Content = styled(ParseContent)`
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.light};
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.ultra};
      line-height: ${({ theme }) => theme.font.size.ultra};
    }
  }
`

const SVGWrapper = styled.div`
  top: 15px;
  right: 15px;

  & > a:hover {
    opacity: 0.75;
  }
`

const Inner = styled.div`
  z-index: 3;
`

const Gradient = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #263280 3.48%, rgba(38, 50, 128, 0) 65.58%);
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.15));
  z-index: 2;
`

interface TeamProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const Team: React.FC<TeamProps> = ({ fields }) => {
  const { allWpMember } = useStaticQuery<GatsbyTypes.teamQueryQuery>(graphql`
    query teamQuery {
      allWpMember {
        edges {
          node {
            recap {
              avatar {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 600)
                  }
                }
              }
              name
              jobtitle
              linkedin
            }
          }
        }
      }
    }
  `)

  const members = allWpMember.edges

  return (
    <Section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Content content={fields.description} className="mb-4" />
            <div className="row mb-5">
              {members.map(({ node }, index) => (
                <div className="col-6 col-md-3" key={index}>
                  <Member className="position-relative">
                    <Gradient className="position-absolute" />
                    <Inner className="position-absolute d-flex flex-column justify-content-between p-3 w-100 h-100">
                      {node.recap?.linkedin && (
                        <SVGWrapper className="d-flex justify-content-end">
                          <a
                            href={node.recap?.linkedin || '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Linkedin />
                          </a>
                        </SVGWrapper>
                      )}

                      <div>
                        <div>
                          <span className="font-weight-bold">
                            {node.recap?.name}
                          </span>
                        </div>
                        <div>
                          <span>{node.recap?.jobtitle}</span>
                        </div>
                      </div>
                    </Inner>
                    <Plaatjie image={node.recap?.avatar} alt="" />
                  </Member>
                </div>
              ))}
            </div>
            <Content content={fields.cta} />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Team
