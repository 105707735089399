import React from 'react'
import { PageProps } from 'gatsby'

// Lazy loading
import loadable from '@loadable/component'

export interface MarketsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Markets
  location: PageProps['location']
}

interface PostProps {
  [key: string]: any
}

const MarketsShell: React.FC<MarketsProps> = ({ fields, location }) => {
  const types: PostProps = {
    default: loadable(() => import('components/flex/Markets/Markets')),
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default MarketsShell
