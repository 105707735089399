import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonPrimaryCSS = css`
  position: relative;
  background-color: ${({ theme }) => theme.color.secondary};
  display: inline-block;
  border-radius: 100px;
  min-width: 124px;
  overflow: hidden;
  z-index: 0;
  transition: border 0.3s;

  & > a,
  & > button {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${(props) => props.theme.color.light} !important;
    padding: 8px 30px;
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    text-decoration: none !important;
  }

  &:active {
    transform: translateY(3px);
  }

  &:not([disabled]):hover {
    cursor: pointer;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 0.5;
      transform-origin: 200px 200px;
      transform: scale(1) translate(-10px, -100px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: opacity 0.3s, border 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    background-color: #302f2f;
    border-color: transparent;
    border-radius: 50%;
    transform: translate(-10px, -70px) scale(0.1);
    opacity: 0;
    z-index: -1;
    transition: transform 0.5s, opacity 0.3s, background-color 0.3s;
  }
`

const StyledButton = styled.span`
  ${ButtonPrimaryCSS};
`

interface ButtonPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimary
